import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { server,cookies,local,parrot_key } from './components/environ';
import { init } from './components/parrot-js/parrot';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import {blue,pink,grey} from '@material-ui/core/colors/';

import UserVerified   from  './components/vendor/signin/UserVerified';
import ErrorPage   from  './components/vendor/signin/ErrorPage';
import ChangePassword from './components/vendor/signin/ChangePassword'

import StickyFooter   from  './components/vendor/stickyfooter';
import ErrorBoundary   from  './ErrorBoundary';
import {theme} from './components/theme'

import Privacy   from  './components/vendor/signin/privacy';
import Terms   from  './components/vendor/signin/terms';
import { Notifications } from 'react-push-notification';

const VendorView        = lazy(() => import('./components/vendor/dashboard/VendorView'));
const SignInForm        = lazy(() => import('./components/vendor/signin/SignInForm'));

function App() {
  
  init(parrot_key);
  return (
    <ThemeProvider theme={theme}>
    <Notifications />
    <Router>
    <Suspense fallback={<div></div>}>
    <Switch>

        <Route  exact path="/signin" component={SignInForm} />
    
        <ErrorBoundary webaddress={window.location.href}>
        <Route  path="/dashboard"       component={VendorView} />
        <Route  path="/changepassword"  component={ChangePassword} />
        <Route  path="/userverified"  component={UserVerified} />
        <Route  path="/errorpage"  component={ErrorPage} />
        <Route path="/terms" component={Terms} />
        <Route path="/privacy" component={Privacy} />
        </ErrorBoundary>
           
    </Switch>
    </Suspense>
    </Router>
    </ThemeProvider>
  );
}

export default App


//ReactDOM.render(<App />, document.querySelector('#app'));


