import {translator} from  './parrot-js/parrot';
import Cookies from 'universal-cookie';

export const site_name  = 'Prospectify';
export const site_type  = 'restaurant';


const domain_add = 'plumqr.com';
const cv_domain_add = 'plumqr.com';
export const email_domain_add = 'plumqr.com';

var inProd =( (window.location.href).includes("localhost")  || window.location.href.includes("192.168") )  ? false : true;
export const local = inProd ? false : true;

var serverI  =  'https://api-mt.' + 'plumqr.com'

export const server = serverI;

// this is for opening menu where
const localf = false;   
export const domain_name  = localf ? 'http://localhost:3002' : 'https://www.' + cv_domain_add ; 


export const store_type =  "food"; 

//export const enablePhoneLogin = false;  //keep it disabled till sms are enabled
export const enablePhoneLogin = (store_type == "ecommerce") ? true : false;
export const help_email  = 'support@' + email_domain_add;

export const parrot_key = '23zdQyXPMFzxDvxNST3f8'; 
export const paddle_vendor_id = 115440; 
export const cookies = new Cookies();

export const MAX_INT = 1000000000; //2^31-1

export const RZP_RN_SDK = false;

export function getCrispWebsiteID() {
    return (store_type == "ecommerce") ? "97a7279c-346e-459f-b525-13a9a1516b8d" 
                                       : "ae584e9f-99e0-4878-83ab-1d9ed1a0a8cc";
}

export function getInspectletWID() {
    return (store_type == "ecommerce") ? 825117434 : 1359076317;
}

export function signUpContent (p) { 
var signUpContent = [
/*{
  title : p.t('Quick and free sign‑up','/line1/title'),
  content : p.t('Enter your email address to create an account.','/line1/content')
}*/
{
  title : 'Mangage prospects',
  content : 'Add prospects quickly and see the analytics',
},
{
  title : 'Full access control',
  content : 'Define the pipeline as per need',
},

];

var ecom_signUpContent = [
/*{
  title : p.t('Quick and free sign‑up','/line1/title'),
  content : p.t('Enter your email address to create an account.','/line1/content')
}*/
{
  title : p.t('Setup under 10 minutes','/line2/title'),
  content :'Get your online store ready with your catalog quickly',
},
{
  title : p.t('Modify with ease','/line3/title'),
  content : p.t('Change the items from the dashboard in real time.','/line3/content'),
},
{
  title : 'Social Media Friendly',
  content : 'Share and promote your catalog on Facebook, Whatsapp etc.',
}
];

 
return (store_type == 'ecommerce') ? ecom_signUpContent : signUpContent;

}

export function subscriptionPlans (p) { 

var subscriptionPlans = [
{
  plan_name : 'FREE TRIAL',
  plan_price : '$ 1',
  plan_duration : 'per month',
  feature1 : 'Single Location',
  feature2 : 'Digital Ordering',
  feature3 : 'Email Support'
},
{
  plan_name : 'BUSINESS',
  plan_price : '$ 10',
  plan_duration : 'per month',
  feature1 : 'Multiple Locations (upto 10)',
  feature2 : 'Includes Order Management',
  feature3 : 'Email Support'
},
{
  plan_name : 'PREMIUM',
  plan_price : '$ 100',
  plan_duration : 'per month',
  feature1 : 'Multiple Locations (upto 100)',
  feature2 : 'Includes Order Management',
  feature3 : 'Premium Support 24/7'
},
];

return subscriptionPlans;
}


export function blog_data (p) { 
 var blog_data = []

 var ecom_blog_data = []
 
 return (store_type == 'ecommerce') ? ecom_blog_data : blog_data;

}

export const test_mode = (local == false) ? 0 : 1;
//export const test_mode = 0 ;

export const available_payment_modes = {
}

export const apmd = {
}

export const invoice_specs_base = [ ]


export function getMOS() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    return "unknown";
}






