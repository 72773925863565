import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { server } from '../../environ';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {translator} from  '../../parrot-js/parrot';

const queryString = require('query-string');


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex'
   },
   largeIcon: {
    width: 60,
    height: 60,
    marginBottom : 20
  },
}));

export default function UserVerified (props) {

  const classes = useStyles();

  var parsed = queryString.parse(props.location.search);
  
  
  setTimeout( () => { window.location = window.location.origin  + '/signin?' + 'email=' + parsed.email  }, 4000 );

  
 
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}  >
       <CheckCircleOutlineIcon style={{fill: "green"}} className={classes.largeIcon}  />

        <Typography variant="body1" display="inline">
          { parsed.msg }
        </Typography>
        
      </div>
     
    </Container>
  );
}
