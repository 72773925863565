import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { site_name,site_type,local,store_type } from '../../environ';


export default function Terms (props) {

  var cn = (props.company_name != undefined) ? props.company_name : "PlumQR"
  var website = (props.website != undefined) ? props.website : "plumqr.com"
  var city = (props.city != undefined) ? props.city : "Karnataka"
  var reg_add = (props.reg_add != undefined) ? " with address  " + props.reg_add : " "

return (
  <div>
<Container component="main" >
<CssBaseline />

<h2>{cn} Terms of Service</h2>
<h3>Terms</h3>
<p>By accessing the website at {website}, you are agreeing to be bound by these terms of service with {cn + " " + props.reg_add}, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>
<h3>Use License</h3>
<ol type="a">
   <li>Permission is granted to temporarily download one copy of the materials (information or software) on {cn}'s website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
   <ol type="i">
       <li>modify or copy the materials;</li>
       <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
       <li>attempt to decompile or reverse engineer any software contained on {cn}'s website;</li>
       <li>remove any copyright or other proprietary notations from the materials; or</li>
       <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
   </ol>
    </li>
   <li>This license shall automatically terminate if you violate any of these restrictions and may be terminated by {cn} at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</li>
</ol>
<h3>Disclaimer</h3>
<ol type="a">
   <li>The materials on {cn}'s website are provided on an 'as is' basis. {cn} makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</li>
   <li>Further, {cn} does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</li>
</ol>

{ props.type == "food" &&
<div>
<h3>Order Delivery/Pickup</h3>
<p> Delivery/Pickup of orders are subjected to: </p>
<ol type="a">
   <li> Your address falling in the defined delivery area of the nearest restaurant in the checkout screen </li>
   <li> In case the delivery locality is not listed in the checkout screen, delivery of orders cannot be placed; However you may choose to pick up your order from the chosen branch. </li>
   <li> Your order is moved to "In Kitchen" or "Confirmed" state after receiving the order, otherwise it will be cancelled</li>
</ol>

<h3>Menu</h3>
<p> The menu is displayed as per the availability of the menu items in the mapped restaurant </p>
<ol type="a">
   <li> In case certain menu items are not listed in the menu page, the particular restaurant does not carry those items in the menu. </li>
   <li> In case of non-availability of ordered product at the mapped restaurant, the order would not be executed. Same would be informed by the restaurant near you. </li>
</ol>


<h3>Modify / Cancel / Refund of the Online Order </h3>
<ol type="a">
<li> The online order once placed cannot be modified or cancelled either through the website or offline by calling the restaurant.</li>
<li> However you can get updated on the status of the order by calling the restaurant directly. </li>
<li> In case the order which is paid through credit card is cancelled due to non-availability of the ordered product at the restaurant, the amount will be returned by reversing the transaction by us. The same would reflect in your next credit card statement. </li>
</ol>

<h3>Process of Online Ordering</h3>
<ol type="a">
<li>
You can place the order by following these steps -
</li>
<li>
Select the Branch you would like to place order to (if available) else, you will be redirected directly to the menu. The branch name and restaurant name will be visible on the top bar.
</li>
<li>
Select the items from the menu and add the items to the cart. 
</li>
<li>
Choose Pick Up option if you want to pick up the order.
</li>
<li>
Choose Delivery option if you want to have your pizza delivered to your address.
</li>
<li>
Select the Delivery Area. Delivery charges may add depending on the area. 
</li>
<li>
Add the address belonging to the same Delivery Area. Orders with mis match of address entered and delivery area will be cancelled.
</li>
<li>
You may have to add your name, phone number and email as well.
</li>
<li>
Press the place order in the bottom bar.
</li>
<li>
You will be redirected to the payments page if the online payment option is chosen.
</li>
<li>
On successful payment the order will be marked "Received" .
</li>
<li> 
In a few minutes,  we will confirm your order and move it to "In Kitchen" or "Confirmed" state, if we are unable to fulfil the order, we will "Cancel" the order and initiate a refund for the same.
</li>
<li>
For any issues, please contact us on the phone numbers / email address mentioned on our website {website}
</li>
</ol>
</div>
}

{ store_type == "ecommerce" &&
<div>
<h3>Order Delivery/Pickup</h3>
<p> Delivery/Pickup of orders are subjected to: </p>
<ol type="a">
   <li> Your address falling in the defined delivery area of the store </li>
   <li> In case the delivery locality is listed in the checkout screen, then delivery of orders can be placed only from those areas; However you may choose to pick up your order from the store if allowed by the store. </li>
   <li> Your order is moved to "Accepted" state after receiving the order, otherwise it will be "Rejected"</li>
</ol>

<h3>Catalog</h3>
<p> The catalog is displayed as per the availability of the products </p>
<ol type="a">
   <li> In case of non-availability of ordered product at the store, the order would not be executed. Same would be informed by the store. </li>
</ol>


<h3>Modify / Cancel / Refund of the Online Order </h3>
<ol type="a">
<li> The online order once placed can only be cancelled if it is still in "Received" state and is Cash-On-Delivery order. </li>
<li> However you can get updated on the status of the order through Orders page or by calling the store directly. </li>
<li> In case the order which is paid online but is rejected due to non-availability of the ordered product, the amount will be returned by reversing the transaction by us. Refunds might take 5-7 business days. </li>
</ol>

<h3>Process of Online Ordering</h3>
<p> You can place the order by following these steps - </p>
<ol type="a">
<li>
Select the store you would like to place order to (if mnultiple options) else, you will be redirected directly to the catalog. Store name will be visible on the top bar.
</li>
<li>
Select the items from the catalog and add the items to the cart. 
</li>
<li>
Choose Pick Up option if you want to pick up the order if the store allows pickup.
</li>
<li>
Choose Delivery option if you want to have your order delivered if the store allows delivery.
</li>
<li>
Select the Delivery Area if applicable. Delivery charges may add depending on the area. 
</li>
<li>
Add the address belonging to the same Delivery Area. Orders with mis match of address entered and delivery area will be cancelled.
</li>
<li>
You may have to add your name, phone number and email as well.
</li>
<li>
Press the place order in the bottom bar.
</li>
<li>
You will be redirected to the payments page if the online payment option is chosen.
</li>
<li>
On successful payment the order will be marked "Received" .
</li>
<li> 
On confirmation of your order, order status will change to "Accepted" state. If we are unable to fulfil the order, we will "Reject" the order and initiate a refund for the same if already paid.
</li>
<li>
For any issues, please contact us on the phone numbers / email address mentioned on our website {website}
</li>
</ol>
</div>
}

<h3>Limitations</h3>
<p>In no event shall {cn} or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on {cn}'s website, even if {cn} or a {cn} authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
<h3>Accuracy of materials</h3>
<p>The materials appearing on {cn}'s website could include technical, typographical, or photographic errors. {cn} does not warrant that any of the materials on its website are accurate, complete or current. {cn} may make changes to the materials contained on its website at any time without notice. However {cn} does not make any commitment to update the materials.</p>
<h3>Links</h3>
<p>{cn} has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by {cn} of the site. Use of any such linked website is at the user's own risk.</p>
<h3>Modifications</h3>
<p>{cn} may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>
<h3>Governing Law</h3>
<p>These terms and conditions are governed by and construed in accordance with the laws of {city} and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>

{ props.backFromTermsToCV  &&
  <Button variant="contained" color="primary" size="medium" display="block" color="primary" style={{ align : "center", margin : "10px" }} onClick={ e => props.backFromTermsToCV() } > Back </Button>
}
</Container>
</div>
);
}
